// import Vue from 'vue';
import Vuex from 'vuex';
// import createPersistedState from "vuex-persistedstate";

import { alert } from './alert.module';
// import { account } from './account.module';
import { users } from './users.module';
import { interns } from './interns.module';
import { ins } from './v1.ins.module';
import { appstate } from './v1.appstate.module';
import { pe } from './v1.pe.module';
import { ml } from './v1.ml.module';
import { inputs } from './v1.inputs.module'

// Vue.use(Vuex);

export const store = new Vuex.Store({
    preserveState: true,
    modules: {
        alert,
        // account,
        users,        
        interns,
        ins,
        inputs,
        appstate,
        pe,
        ml
    },
    // plugins: [createPersistedState()],
});
