// import config from 'config';
import { authHeader } from '../_helpers';

export const mlService = {
    getApiKey,
    getAll,
    saveTest,
    getAllSecteurs,
    getAllVisuels
};

async function getApiKey() {    
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return await fetch(`${process.env.VUE_APP_ROOT_API}apikey/decode`, requestOptions).then(handleResponse);    
}

async function getAll() {    
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };    
    return await fetch(`${process.env.VUE_APP_ROOT_API}gems`, requestOptions).then(handleResponse);    
}


 async function getAllSecteurs() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return await fetch(`${process.env.VUE_APP_ROOT_API}secteurs`, requestOptions).then(handleResponse);
}

 async function getAllVisuels() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return await fetch(`${process.env.VUE_APP_ROOT_API}visuels`, requestOptions).then(handleResponse);
}

async function saveTest(test) {

    const requestOptions = {
        method: 'POST',        
        headers: authHeader(),
        body: JSON.stringify(test)
    };

      //   a remettre modifns remettre
    const response = await fetch(`${process.env.VUE_APP_ROOT_API}ml/record`, requestOptions);
    const intern = await handleResponse(response);    
     if (intern.access_token) {       
         localStorage.setItem('user', JSON.stringify(intern));
     }
    return requestOptions;
    // return bodyintern_1;
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);    
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // logout();
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }       
        return data.data;
    });
}