<template>
    <div className="demo-sticker" title="Swich Dark">
      <a href="#" @click.prevent="handleSubmit()" id="changeMode">
        <i :className="icon" aria-hidden="true"></i>
      </a> 
    </div>    
    <!-- End switcher ModifNS --> 
</template>

<script>
import { useStore } from 'vuex';
import { computed } from '@vue/reactivity';
export default {  
    setup(){ 
        const store = useStore()
        const icon = computed(() =>  { 
            let iconStatus = store.getters['appstate/getStatus']            
            return iconStatus.icon })      

            const handleSubmit = () =>  {
                store.dispatch('appstate/change')                                     
            }   
            
        return {
            icon,
            handleSubmit            
        }
    } 
}
</script>
