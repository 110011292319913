import { inscriptionsService } from '@/_services/v1.inscriptions.service';
import { crypt, decrypt } from '@/_helpers/cryptuser';
import router from '../_helpers/router';


// const User = JSON.parse(localStorage.getItem('User'));
// cryptage et décryptage pour appli officielle 
const tUser = localStorage.getItem('user') 
const Data = tUser ? decrypt(tUser): null
// fin
// console.log('DUSER', dUser, User)
const state = Data
    ? { all: {  user: Data.user ,access_token: Data.access_token, stepSaved: false, verified: false }, existEmail: false, step: 0, error: {}   }
    : { all: {  user: null , stepSaved: false, verified: false }, existEmail: false, step: 0 , error: {} };

const getters = {   
    savedUser( state ) {
      return state.all.user;
    },    
    stepSaved(state) {
        return state.all.stepSaved
    },
    existEmail(state) {
        return state.existEmail
    },
    wrongCode(state) {
        return (state.step >0)
    }
}

const actions = {     
    //                 router.push('/validation');
    //                 router.push('/deja-inscrit');
    async saveData({state, commit}) {  
       await inscriptionsService.register(state.all.user)
            .then((intern) => {
                if ( intern ) {                                             
                    commit('registerSuccess', intern) 
                    commit('storeLocaluser');    
                } else {                    
                    commit('registerFailure', {msg:'Email already exists !',status: true });
                }            
           },
           error => { commit('registerFailure', error);
            // router.push('/deja-inscrit');
           }
       )},

    async updateData({ commit }, intern) {
        inscriptionsService.update(intern)
            .then(
                intern => commit('updateSuccess', intern),
                error => commit('updateFaillure', error)
            );
    },  
    async checkDigits({ commit }, digit ) {   
        if(state.step >= 3 ) {
             state.step=1
             router.push('/');        
        }  

    await inscriptionsService.verifyDigits(digit)
        .then(
            intern => { 
                console.log('INTERN', intern)
                if( intern.access_token )  commit('registerSuccess', intern)
                else commit('digitsFailure', intern );
            
            },
            error => commit('digitsFaillure', error)
        );
    },  

    saveGems({commit}, payload) {
        commit('storeGems', payload)        
        commit('storeLocaluser');    
    },
    saveUser({commit}, payload) {
        commit('storeUser', payload)             
        commit('storeLocaluser');     
    },
    
    saveSocio({commit}, payload) {
        commit('storeSocio', payload)             
        commit('storeLocaluser');     
    },

    saveSecteurs({commit}, payload) {
        commit('storeSecteurs', payload)            
        commit('storeLocaluser');    
    },
    savePictures({commit}, payload) {
        commit('storePictures', payload)            
        commit('storeLocaluser');    
    },

    resetData({ commit }) {
        localStorage.removeItem('user');
        commit('resetUser');
    },
    start({ dispatch}) {
        dispatch('resetData')        
        router.push('/register');        
    }  
};

const mutations = {   
    storeLocaluser(state) {                    
    state.all.stepSaved = false;
    console.log('HOOP DANS STORE LOCAL USER')
    let cr = crypt((state.all));           
    localStorage.setItem('user', cr)
    },
    //               
    resetUser(state){
        // state.all = { user: {}, gems:{}, secteurs: {}, pictures:{} };
        state.all = { user: {}, verified:false };
        state.error = {};
        state.step = 0;
    },    
    //    
    storeUser(state, user){
        //Save all data in the store        
        state.all =  {...state.all, user : user }              
    },
    //
    registerSuccess(state, intern) {
        state.all = intern;
        state.error =  {};
        state.existEmail = false;
    },
    registerFailure(state, error) {        
        state.error = error; 
        state.existEmail = true;
        localStorage.removeItem('user');
    },
    //
    digitsFailure(state, error) {
        // state.all.user = {};
        state.error = error;       
        state.step++;
        localStorage.removeItem('user');
    },
    //
    updateSuccess(state, intern) {
        state.all.user = intern;
    },
    updateFailure(state) {
        state.all.user = {};
    },
    //
    storeSocio(state, socio){
        //Save all data in the store        
        state.all.user =  {...state.all.user, ...socio }              
    },
    //
    storeGems(state, gems){
        let tGems = gems.map(prod => { return { 'gem_id': prod.option_id, value: prod.value }})                      
        //Save all data in the store             
        state.all.user =  {...state.all.user, gems : tGems }         
    },
    //    
    storeSecteurs(state, secteurs){
        let tSecteurs = secteurs.map(prod => { return { 'secteur_id': prod.option_id, value: prod.value }})            
        //Save all data in the store                
        state.all.user =  { ...state.all.user, secteurs : tSecteurs }              
    },
    //
    storePictures(state, pictures){

        let tVisuel = pictures.map(prod => { return { 'visuel_id': prod.option_id, value: prod.value }})            
        //Save all data in the store                
        state.all.user =  { ...state.all.user, pictures : tVisuel }              
    }
};

export const ins = {
    namespaced: true,
    state,
    actions,  
    getters,
    mutations
};