import { mlService } from '../_services';

const state = { loading : false , 
    status: { items:[], secteurs:[], pictures: [] }
};

const getters = {
    loading(state){
        return state.loading
    },
    items( state ) {
      return state.status.items;
    },
    
    secteurs ( state ) {
      return state.status.secteurs;
    },
    visuels ( state ) {
      return state.status.pictures;
    },

}
const actions = {

    // getApiKey({commit}) {
    //     mlService.getApiKey()
    //         .then(                
    //             gems => commit('getApiKeySuccess', gems),
    //             error   => commit('getAllFailure', error)                
    //         );   
    // },
    getAllGems({ commit}) {
        commit('getRequest', true);                
        mlService.getAll()
            .then(
                gems => commit('getAllGemsSuccess', gems),
                error   => commit('getAllFailure', error)
            );            
    },
    getAllSecteurs({ commit }) {
        commit('getRequest', true);
        mlService.getAllSecteurs()
            .then(
                gems => commit('getAllSecteursSuccess', gems),
                error   => commit('getAllFailure', error)
            );    
    },
    getAllPictures({ commit }) {
        commit('getRequest', true);
        mlService.getAllVisuels()
            .then(
                gems => commit('getAllPicturesSuccess', gems),
                error   => commit('getAllFailure', error)
            );
    }
};


const mutations = {
    getRequest(state, val) {        
        state.loading =  val ;
    },
    // getApiKeySuccess( gems) {
    //     console.log('DATA DATA ******', gems) 
    // },
    getAllGemsSuccess(state, gems) {
        state.status = {...state.status, items : gems }     
    },
    getAllSecteursSuccess(state, gems) {
        state.status = { ...state.status, secteurs : gems }        
    },
    getAllPicturesSuccess(state, gems) {
        state.status = { ...state.status, pictures : gems }        
    },
    getAllFailure(state, error) {
        console.log('ERRR ERR', error)
        state.status = { error };
    }
};

export const inputs = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
