import { postOptions, getOptions } from '@/_helpers/api';

export const inscriptionsService = {    
    getAll,
    register,
    update,
    sendEmail,
    verify,
    verifyDigits
};

function getAll() {
    const options = getOptions();      
    return fetch(`${process.env.VUE_APP_ROOT_API}interns`, options).then(handleResponse);
}

async function register(intern) {
    const ins = { ...intern, password:'xxxxxxxx', confirm_password:"xxxxxxxx"}
    const options = postOptions(ins);  
    return fetch(`${process.env.VUE_APP_ROOT_API}register`, options)      
       .then(handleResponse)
       .catch(error => console.log('error', error));
}


async function update(intern) {   
    const options = postOptions(intern); 
    return await fetch(`${process.env.VUE_APP_ROOT_API}update`, options).then(handleResponse);
}

function sendEmail(email) {
    const options = postOptions(email); 
    return fetch(`${process.env.VUE_APP_ROOT_API}user/tokenresend`, options).then(handleResponse);
}


async function verifyDigits(digit) {
    const ins = {digits : digit};
    const options = postOptions(ins);    
    return fetch(`${process.env.VUE_APP_ROOT_API}user/verify/code`, options)
        .then(handleResponse)
        .catch(error => console.log('error', error))        
}

function verify(id) {
    const options = getOptions();    
    return fetch(`${process.env.VUE_APP_ROOT_API}user/verify/token/${id}`, options)
        .then(handleResponse)
        .then(user => {                      
            if (user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes                                
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function handleResponse(response) {    
    return response.text().then(text => {
        const data = text && JSON.parse(text);        
        if (response.status === 202 ) {                                                
            const error = (data && data.message) || response.statusText; 
            console.log('erreur',error)           
        }
        if (!response.ok) {         
            if (response.status === 401 || response.status === 404 ) {                                    
                location.reload(true);
            }            
            const error = (data && data.message) || response.statusText;                       
            return Promise.reject(error);
        }                
        return data.data;
    });
}