import { authHeader } from '../_helpers/auth-header';

export const internsService = {
    getTags,
    getAll,
    register,
    update,
    sendEmail,
    verify
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    
    return fetch(`https://apigepe.gtreem.net/api/interns`, requestOptions).then(handleResponse);
}

function getTags() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    
    return fetch(`https://apigepe.gtreem.net/api/tags`, requestOptions).then(handleResponse);
}

function register(intern) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(intern)
    };

    return fetch(`https://apigepe.gtreem.net/api/register`, requestOptions)
        .then(handleResponse)
        .then(intern => {          
            // login successful if there's a jwt token in the response            
            if (intern.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes                                
                localStorage.setItem('user', JSON.stringify(intern));
            }
            return intern;
        });
}

function update(intern) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(intern)
    };

    return fetch(`https://apigepe.gtreem.net/api/update`, requestOptions).then(handleResponse);
}

function sendEmail(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(email)
    };

    return fetch(`https://apigepe.gtreem.net/api/user/tokenresend`, requestOptions).then(handleResponse);
}

function verify(id) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    return fetch(`https://apigepe.gtreem.net/api/user/verify/${id}`, requestOptions)
        .then(handleResponse)
        .then(user => {          
            // login successful if there's a jwt token in the response            
            if (user.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes                                
                localStorage.setItem('user', JSON.stringify(user));
            }
            return user;
        });
}

function handleResponse(response) {
    
    return response.text().then(text => {
        const data = text && JSON.parse(text);
          if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                    // logout(); modif ns
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        
        return data.data;
    });
}