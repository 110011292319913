import { mlService } from '@/_services/v1.ml.service';
import { crypt, decrypt } from '@/_helpers/cryptuser';
import router from '../_helpers/router';


const Dossier = JSON.parse(localStorage.getItem('Dossier'));
// cryptage et décryptage pour appli officielle 
const tDossier = localStorage.getItem('cDossier')
const dDossier = tDossier ? decrypt(tDossier): null
// fin
const state = Dossier
    ? { all: {  dossier: Dossier , cDossier: dDossier } }
    : { all: {  dossier: null } };

const getters = {   
    savedDossier( state ) {
      return state.all.dossier;
    },    
}

const actions = {     
    async saveData({state}) {  
        await mlService.saveTest(state.all.user);            
    },        
    saveFolderSocio ({commit}, payload) {
        commit('storeFolderSocio', payload)        
        commit('storeLocalDossier');    
    },
    saveDebrief({commit}, payload) {
        commit('storeDebrief', payload)             
        commit('storeLocalDossier');     
    },
    saveSecteurs({commit}, payload) {
        commit('storeSecteurs', payload)            
        commit('storeLocaluser');    
    },
    resetData({ commit }) {
        localStorage.removeItem('dossier');
        localStorage.removeItem('cDossier');
        commit('resetDossier');
        // router.push('/');        
    },
    start({ dispatch}) {
        dispatch('resetData')        
        router.push('/pole-emploi');        
    }
};

const mutations = {   
    storeLocalDossier(state) {                                   
        let cr = crypt(JSON.stringify(state.all.dossier));           
            // let decrypted = CryptoJS.AES.decrypt(encrypted, key);                  
        localStorage.setItem('Dossier', JSON.stringify(state.all.dossier));
        localStorage.setItem('cDossier', cr)
    },
    //               
    resetDossier(state){
        // state.all = { user: {}, gems:{}, secteurs: {}, pictures:{} };
        state.all = { dossier: {}, cDossier: {} };
    },    
    //    
    storeFolderSocio(state, dossier){
        //Save all data in the store        
        state.all =  {...state.all , dossier: dossier }              
    },
    //
    storeDebrief(state, debrief){
       // let tGems = debrief.map(prod => { return { 'gem_id': prod.option_id, value: prod.value }})                      
        //Save all data in the store             
        state.all.dossier =  {...state.all.dossier, debrief : debrief }         
    },   
    //
    storePictures(state, pictures){

        let tVisuel = pictures.map(prod => { return { 'visuel_id': prod.option_id, value: prod.value }})            
        //Save all data in the store                
        state.all.dossier =  { ...state.all.dossier, pictures : tVisuel }              
    }
};

export const pe = {
    namespaced: true,
    state,
    actions,  
    getters,
    mutations
};