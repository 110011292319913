import { internsService } from '../_services/interns.service';
// import cryptoRandomString from 'crypto-random-string';
import router from '../_helpers/router';

const getDefaultState = () => {
    return {
        interns: {},
        tags: {},
        status: {},
        verify: [],
        biniou: [],
        data: {
            timeForm1: 0,
            timeForm2: 0,
            timeForm3: 0,
            rating: []
        }
    }
}

const getters = {
    getVerify () {
      return state;
    }
};
    


const state = getDefaultState()

const actions = {

    getInterns({ commit }) {
        commit('getAllRequest');
        internsService.getAll()
            .then(
                interns => commit('getAllSuccess', interns),
                error   => commit('getAllFailure', error)
            );
    },

    savePersonalData({ commit }, data) {
        commit('storePersonalData', data);
    },

    saveFormationData({ commit }, data) {
        commit('storeFormationData', data);
    },

    resetData({ commit }) {
        commit('resetUser');
    },

    getTags({ commit }) {
        commit('getTagsRequest');

        internsService.getTags()
            .then(
                tags => commit('getTagsSuccess', tags),
                error => commit('getTagsFailure', error)
            );
    },

    register({ commit }, intern) {
        commit('registerRequest');

        internsService.register(intern)
            .then(
                intern => {
                    commit('registerSuccess', intern);
                    router.push('/validation');
                },
                error => {
                    commit('registerFailure', error);
                    
                    internsService.sendEmail({
                        email: intern.email
                    });
                    router.push('/deja-inscrit');
                }
            );
    },

    update({ commit }, intern) {
        internsService.update(intern)
            .then(
                intern => commit('updateSuccess', intern),
                error => commit('updateFaillure', error)
            );
    },

    sendEmail( email) {
        internsService.sendEmail(email);
    },

    verify({ commit }, id) {
        commit('verifyRequest');
        commit('storePersonalData', {id: id});
        internsService.verify(id)
            .then(
                intern => {
                    commit('verifySuccess', intern);
                    commit('storePersonalData', {
                        email: intern.user.email,
                        firstname: intern.user.prenom,
                        lastname: intern.user.nom,
                        town: intern.user.ville,
                        phone: intern.user.telephone,
                        age: intern.user.tranche_age,
                        fileNumber: intern.user.num_dossier,
                        register: intern.user.pole_emploi,
                    });
                    router.push('/form');
                },
                error => {
                    commit('verifyFaillure', error);
                    router.push('/register');
                }
            );
    }
};

const mutations = {
    getAllRequest(state) {
        state.interns = { loading: true };
    },
    getAllSuccess(state, interns) {
        state.interns = { items: interns };
    },
    getAllFailure(state, error) {
        state.interns = { error };
    },
    storePersonalData(state, data){
        //Save all data in the store        
        state.biniou = { ...state.biniou, data }
     
        state.data.id = data.id?data.id:state.data.id;
        state.data.email = data.email?data.email:state.data.email;
        state.data.gender = data.gender?data.gender:state.data.gender;
        state.data.firstname = data.firstname?data.firstname:state.data.firstname;
        state.data.lastname = data.lastname?data.lastname:state.data.lastname;
        state.data.age = data.age?data.age:state.data.age;
        state.data.town = data.town?data.town:state.data.town;
        state.data.phone = data.phone?data.phone:state.data.phone;
        state.data.register = data.register?data.register:state.data.register;        
        state.data.timeForm1 += data.timeForm1?data.timeForm1:0;
        state.data.timeForm2 += data.timeForm2?data.timeForm2:0;
        state.data.timeForm3 += data.timeForm3?data.timeForm3:0;
    },
    storeFormationData(state, data){
        //Save all data in the store
        state.data.rating = data.rating;
    },
    resetUser(state){
        Object.assign(state, getDefaultState())
    },
    getTagsRequest(state) {
        state.tags = { loading: true };
    },
    getTagsSuccess(state, tags) {
        state.tags = { items: tags };
    },
    getTagsFailure(state, error) {
        state.tags = { error };
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state, intern) {
        state.status = intern;
        state.data.email = intern.email;
    },
    registerFailure(state, error) {
        state.status = error;
    },
    updateSuccess(state, intern) {
        state.status = intern;
    },
    updateFailure(state) {
        state.status = {};
    },
    verifyRequest(state) {
        state.status = { loading: true };
    },
    verifySuccess(state, intern) {
        state.status = intern;
        state.verify.push(state.data.id);
    },
    verifyFailure(state, error) {
        state.status = { error };
    },
};

export const interns = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};