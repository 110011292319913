import { decrypt } from '@/_helpers/cryptuser';

export function authHeaders() {
  // return authorization header with token
  const tUser = localStorage.getItem('user') 
//   let user = JSON.parse(localStorage.getItem('user')); 
  let Data = tUser ? decrypt(tUser): null
  console.log('USSER ------------------------------', Data.user , Data.access_token )
  if (Data.user && Data.access_token) {
    console.log('************* >>>>>>>>>>>>>>  AVEC TOKEN')
    return { 
        'Authorization': 'Bearer ' + Data.access_token,
        'x-api-key': process.env.VUE_APP_API_KEY,
        'Content-Type': 'application/json;charset=utf-8',
        'Accept': 'application/json'
    };
} else {
      console.log('************* >>>>>>>>>>>>>>  SANS TOKEN')
      return { 
          'x-api-key': process.env.VUE_APP_API_KEY , 
          'Content-Type': 'application/json;charset=utf-8',
          'Accept': 'application/json' 
      };
  }
}

export function postOptions( ins ){
    
    const t =  {
        method: 'POST',
        headers: authHeaders(),
        body: JSON.stringify(ins)      
    };
    
    return t;
  
}

export function getOptions(){
    return  {
        method: 'GET',
        headers: authHeaders(),        
    };
}
