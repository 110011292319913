window.dataLayer =  window.dataLayer || []
const state = { etat : false , loading :false, 
    status: {  
        class: 'light', 
        icon: 'fa fa-moon-o',
        message:null},
    layer: {
        event : 'Formulaire',
        formName: 'Basique',
        pageUrl: `${process.env.VUE_APP_ROOT_API}`,
        pageTitle: '',
        styleMode: 'light'
    }}
;

const getters = {
    getStatus (state) {
      return state.status;
    },
    isLoading(state){
        return state.loading
    }
};
    
const actions = {
    change({ commit, dispatch } ) {                   
        commit('change');        
        // window.dataLayer.push({
        //     'event' : 'Etapes',
        //     'pageUrl' : 'https://gepedapi.globaltree.fr',
        //     'pageTitle': state.status.class,
        //     'styleMode': state.status.class
        // })
        dispatch('changeLayer', { 'styleMode' : state.status.class })
        document.body.classList.remove(...document.body.classList);             
        document.body.classList.add(state.status.class);
    
    },
    changeLayer({commit}, payload) {
        commit('layerChange', payload)
        window.dataLayer.push(state.layer)
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }) {
        commit('clear');
    },
    showSpinner({commit}) {                
        commit('loading', true)
    },
    hideSpinner({commit}){        
        commit('loading', false)
    }

};

const mutations = {
    loading(state, val) {
        state.loading = val
    },
    layerChange(state, payload) {
        state.layer = { ...state.layer, ...payload }
    },
    change(state) {
        state.etat = !state.etat;          
        state.status = state.etat
        ? { class: 'dark',  icon: 'fa fa-lightbulb-o', message: 'on passe en nocture'}
        : { class: 'light',  icon: 'fa fa-moon-o', message: 'On passe en jour'};        
    },
    error(state, message) {
        state.type = 'alert-danger';
        state.message = message;
    },
    clear(state) {
        state.status = null;
        state.message = null;
    }
};

export const appstate = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
