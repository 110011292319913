import CryptoJS from 'crypto-js';
const key = "ASOI171160"
export function crypt(user) {    
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(user), key);
    encrypted = encrypted.toString();
    return encrypted;
}

export function decrypt(cUser) {    
    let decrypted = CryptoJS.AES.decrypt(cUser, key);
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    // return JSON.parse(decrypted);    
    // console.log('decrypté === ===== == = = == =', JSON.parse(decrypted))
    return JSON.parse(decrypted);    
}
