<template>
  <div>
    <main class="yellow">
      <!-- <v-offline
      @detected-condition="amIOnline"
      online-class="online"
      offline-class="offline"
    >
      <template v-if="online">
        <div class="flex w-full h-full justify-center items-center text-6xl">
          ⚡️ Vous n'êtes plus connectés à internet. Vérifiez votre connection.
        </div>
      </template>
        <template v-if="!online">
            <div class="flex w-full h-full justify-center items-center text-6xl">              
            </div>
        </template>
      </v-offline> -->
      <LightToDark />      
            
      <CircleLoader  :size="size"/>
      <RouterView />
    </main>
  </div>
  
</template>

<script>
import CircleLoader from './components/footer/CircleLoader.vue';
import LightToDark from './components/composants/LightToDark.vue';
// import { VOffline } from "v-offline";
// import { onBeforeRouteLeave } from 'vue-router';
import { onBeforeMount } from 'vue';

export default {
    setup(){
      const size = "120px"     
      let online = true;

     function amIOnline(e) {      
        online = e;
      }

      function  preventNav(event) {     
          event.preventDefault();
          // Chrome requires returnValue to be set.
          event.returnValue = "";
      }    
      onBeforeMount(() => {
        window.addEventListener("beforeunload", preventNav);
        // this.$once("hook:beforeDestroy", () => {
        //   window.removeEventListener("beforeunload", this.preventNav);
        //   });
      })
      
      // onBeforeRouteLeave((to, from, next) => {
      //   if (!window.confirm("Vous voulez quitter le site web ?")) {
      //     return;
      //   }
      //   next();
      // })

      return {
          online,
          amIOnline,
          size,
          preferences: [
                {
                    title: 'Analytics',
                    description: 'Description à écrire.',                    
                    items: [{}],
                    // items: [{ label: 'Active', value: 'performance', isRequired: false }],
                    // items: [
                    //     { label: 'GoogleAnalytics', value: 'ga', isEnable: false },
                    // ],
                },
            ]
      }
    }
    ,    
    components: { CircleLoader, LightToDark } // HeaderNav 
 // HeaderNav 
}
</script>
<style  lang="scss">

* {
  margin: 0;
}
.offline {
  width: 100%;
  height: 100%;
  background-color: #fc9842;
  background-image: linear-gradient(315deg, #fc9842 0%, #fe5f75 74%);
}
.online {
  width: 100%;
  visibility: hidden;
  height: 100%;  
}

</style>